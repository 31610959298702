import { ICountryCode, UserRole } from 'src/react-app-env'
import { isHrAdminRoute, isManagerRoute } from 'src/utils/routeUtils'
import {
  hrAdminDashboardRoute,
  journeyMapRoute,
  managerDashboardRoute
} from 'src/routes/constants'
import { capitalize } from 'lodash'
import { NavigateFunction, Location } from 'react-router-dom'

export const GLOBAL_COUNTRY = 'GLOBAL'

export const getCurrentUserRole = (location: Location): UserRole => {
  const { pathname } = location

  if (isManagerRoute(pathname)) {
    return 'manager'
  }

  if (isHrAdminRoute(pathname)) {
    return 'hradmin'
  }

  return 'employee'
}

export const switchToRole = (role: UserRole, navigate: NavigateFunction) => {
  if (role === 'manager') {
    navigate(managerDashboardRoute)
  }
  if (role === 'hradmin') {
    navigate(hrAdminDashboardRoute)
  }
  if (role === 'employee') {
    navigate(journeyMapRoute)
  }
}

export const getIsEmployee = (roles: UserRole[]): boolean =>
  roles && roles.indexOf('employee') !== -1

export const getIsManager = (roles: UserRole[]): boolean =>
  roles && roles.indexOf('manager') !== -1

export const getIsHrAdmin = (roles: UserRole[]): boolean =>
  roles && roles.indexOf('hradmin') !== -1

export const getCountryCode = (user: any): ICountryCode =>
  user?.countryCode as ICountryCode

export const getCustomer = (data: any): string => {
  const customer = data?.customer as string
  return customer ? capitalize(customer) : null
}

// plop: isCustomer
export const isWorkday = (customer: string): boolean => customer === 'Workday'
export const isAdobe = (customer: string): boolean => customer === 'Adobe'
export const isGoogle = (customer: string): boolean => customer === 'Google'
export const isIntuitive = (customer: string): boolean =>
  customer === 'Intuitive'
export const isRoche = (customer: string): boolean => customer === 'Roche'
export const isIBM = (customer: string): boolean => customer === 'Ibm'
