import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import { ITimelinePeriod } from 'src/react-app-env'
import Calendar from 'src/components/Calendar'
import useComponentRect, { IRect } from 'src/components/hooks/useComponentRect'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import { TIMELINE_DETAILS_PUSH_DURATION } from 'src/features/Timeline/components/vertical/animationConstants'
import Default from 'src/components/Calendar/styles/Default'
import DefaultTransparent from 'src/components/Calendar/styles/DefaultTransparent'
import { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/UIKit'
import usePrevious from 'src/components/hooks/usePrevious'
import ArrowView from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/ArrowView'
import DatePickerAlert from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Alert'
import AccessibilitySelfFocusText from 'src/components/AccessibilitySelfFocusText'
import ScreenContext from 'src/contexts/ScreenContext'
import {
  mobileButtonContainerPaddingMixin,
  mobileMaxWidthMixin
} from 'src/theme/utils'
import { getChanges, getPeriodDuration, calculateDuration } from '../methods'
import { isBlankDate, areSameDates } from 'src/utils/dateUtils'
import { useResizeDetector } from 'react-resize-detector'
import InfoMessageView from '../components/InfoMessageView'
import { isCertificationRequired } from 'src/utils/leaveUtils'
import Title from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Title'
import { getPeriodKeyDate } from 'src/utils/periodUtils'

interface IProps {
  className?: string
  period: ITimelinePeriod
  y?: number
  alignsToTop?: boolean
  bottomOffset?: number
  onExit: () => void
}

const Container = styled.div<{
  $y: number
  $alignsToTop: boolean
  $containerHeight: number
}>`
  transition: all ${TIMELINE_DETAILS_PUSH_DURATION}ms;
  display: flex;

  ${props =>
    props.theme.isDesktop
      ? css`
          position: absolute;
          width: 445px;
          filter: drop-shadow(0 0 10px rgb(0 0 0 / 5%));

          ${() => {
            const { $y, $alignsToTop, $containerHeight } = props
            if ($alignsToTop) {
              return css`
                top: ${$y}px;
              `
            } else {
              return css`
                top: ${$y - $containerHeight}px;
                align-items: flex-end;
              `
            }
          }}
        `
      : css`
          width: 100%;
          height: 100%;
          overflow-y: scroll;
          margin: 0 auto;
        `}
  ${mobileMaxWidthMixin};
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.colors.light100};

  ${props =>
    props.theme.isDesktop
      ? css`
          border: 1px solid ${props.theme.colors.dark05};
          border-radius: 8px;
          width: 429px;
          padding-bottom: 16px;
          overflow: hidden;
        `
      : css`
          width: 100%;
          height: auto;
        `}
`

const Text = styled.span`
  font-size: 14px;
  line-height: 150%;
  white-space: pre-line;
  width: calc(100% - 32px);
  color: ${props => props.theme.colors.dark60};
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 16px;
        `
      : css`
          margin: 0 24px;
        `}
`

const CalendarPlaceholder = styled.div<{ $height: number }>`
  width: 1px;
  height: ${props => props.$height}px;
`

const ButtonsContainer = styled.div`
  display: flex;
  ${props =>
    props.theme.isDesktop
      ? css`
          width: calc(100% - 32px);
          margin: 16px 0 0;
        `
      : css`
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgb(255 255 255 / 95%);
          justify-content: center;
          align-items: center;
          margin: 16px;
        `}

  ${mobileButtonContainerPaddingMixin};
`

const buttonMixin = css`
  flex: 1;
  ${props =>
    props.theme.isMobile &&
    css`
      font-size: 16px;
      height: 48px;
    `}
`

const CancelButton = styled(Button)`
  ${buttonMixin};
  &:not(:last-child) {
    flex: 1;
    width: 155px;
    margin-right: 16px;
  }
`

const RemoveButton = CancelButton

const ConfirmButton = styled(Button)`
  ${buttonMixin}
`

const AlertsContainer = styled.div`
  width: 100%;
  width: calc(100% - 32px);
  margin: 16px 0 8px;
`

const InfoMessageWrapper = styled.div`
  width: calc(100% - 32px);
  margin: 10px 16px;
`

const TitleWrapper = styled(Title)`
  margin: 16px 16px 20px;
`

/**
 * Picker is represented by a single calendar which allows changing
 * a date defined by periodKeyDate configuration option.
 *
 * Sends period duration including changes for a whole timeline.
 */
const LeaveDateTimelinePicker = React.memo((props: IProps) => {
  const context: ITimelineContext = useContext(TimelineContext)
  const {
    dueDate,
    leaveStartDate,
    leave,
    onNewChanges,
    timelinePeriods,
    hasTimelineChanges,
    leaveHolidays,
    onCalendarActiveDateChanged,
    updateLeave
  } = context
  const { y, alignsToTop, bottomOffset, onExit } = props
  const period = props.period
  const { type } = period
  const { t } = useTranslation()
  const { isDesktop, isMobile } = useContext(ScreenContext)

  const [lastHeight, setLastHeight] = useState<number>(420)
  const [initialDate, setInitialDate] = useState<Moment>(null)
  // There is an issue https://github.com/wojtekmaj/react-calendar/issues/342
  // So, every time 'type' prop changes, we re-mount the calendar on desktop
  const [isCalendarMounted, setIsCalendarMounted] = useState(isMobile)
  const [initialTimelinePeriods] = useState(timelinePeriods)
  const hadTimelineChanges: boolean = usePrevious(hasTimelineChanges)

  const containerRef: any = useRef(null)
  const containerRect: IRect = useComponentRect(containerRef)
  const textRef: any = useRef(null)
  const textRect: IRect = useComponentRect(textRef)
  const forceUpdate = useForceUpdate()

  useEffect(() => {
    setInitialDate(null)
  }, [type])

  useEffect(() => {
    if (!hasTimelineChanges && hadTimelineChanges) {
      setInitialDate(null)
    }
  }, [hasTimelineChanges, hadTimelineChanges])

  useEffect(() => {
    if (isMobile) {
      return
    }

    if (containerRect.height !== 0) {
      setLastHeight(containerRect.height - textRect.height)
    }
    setIsCalendarMounted(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  useEffect(() => {
    if (!isCalendarMounted) {
      setIsCalendarMounted(true)
      requestAnimationFrame(() => {
        forceUpdate()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCalendarMounted])

  const alerts: string[] = Array.from(period.timelineConfig.alerts)

  if (
    period.type === 'PreDelivery' &&
    isCertificationRequired(leave, period.type)
  ) {
    alerts.push(t('preDeliverySickTimeCertificateRequired'))
  }

  if (
    period.type === 'Recovery' &&
    isCertificationRequired(leave, period.type)
  ) {
    alerts.push(t('postDeliveryDisabilityCertificateRequired'))
  }

  const canBeRemoved = useMemo(() => period.timelineConfig.removable, [period])
  const onRemove = useCallback(() => {
    onExit()
    const changes: any = getChanges({
      timelinePeriods,
      period,
      duration: 0,
      dueDate: dueDate.current.toISOString(),
      startDate: leaveStartDate.current.toISOString(),
      leave
    })
    onNewChanges(changes, true)
  }, [
    timelinePeriods,
    leave,
    leaveStartDate,
    onNewChanges,
    onExit,
    period,
    dueDate
  ])

  const resetChanges = useCallback(() => {
    const changes = getChanges({
      timelinePeriods: initialTimelinePeriods,
      period,
      duration: getPeriodDuration(period),
      dueDate: dueDate.current.toISOString(),
      startDate: leaveStartDate.current.toISOString(),
      leave
    })
    onNewChanges(changes)
  }, [
    dueDate,
    initialTimelinePeriods,
    leave,
    leaveStartDate,
    period,
    onNewChanges
  ])

  const updatedPeriod = timelinePeriods.find(p => p.hash === props.period.hash)
  const momentCurrentMinMax =
    updatedPeriod[updatedPeriod.timelineConfig.periodKeyDate]

  const onCancel = useCallback(() => {
    onExit()
    if (initialDate === null) {
      return
    }
    if (isBlankDate(initialDate) && canBeRemoved) {
      return onRemove()
    }
    resetChanges()
  }, [onExit, initialDate, canBeRemoved, onRemove, resetChanges])

  const onChange = useCallback(
    (date: Moment) => {
      if (initialDate && areSameDates(date, initialDate)) {
        return resetChanges()
      }
      const { periodKeyDate } = updatedPeriod.timelineConfig
      const changes: any = getChanges({
        timelinePeriods,
        period,
        duration: calculateDuration(updatedPeriod, periodKeyDate, date),
        dueDate: dueDate.current.toISOString(),
        startDate: leaveStartDate.current.toISOString(),
        leave
      })
      onNewChanges(changes)
    },
    [
      leave,
      onNewChanges,
      resetChanges,
      initialDate,
      updatedPeriod,
      dueDate,
      leaveStartDate,
      period,
      timelinePeriods
    ]
  )

  const onConfirm = useCallback(() => {
    const changes: any = getChanges({
      timelinePeriods,
      period,
      duration: getPeriodDuration(updatedPeriod),
      dueDate: dueDate.current.toISOString(),
      startDate: leaveStartDate.current.toISOString(),
      leave
    })
    updateLeave(changes)
  }, [
    updateLeave,
    timelinePeriods,
    period,
    updatedPeriod,
    dueDate,
    leaveStartDate,
    leave
  ])

  const onDateChange = useCallback(
    (d: any) => {
      if (!initialDate) {
        setInitialDate(momentCurrentMinMax.current)
      }

      onChange(d)
    },
    [initialDate, onChange, momentCurrentMinMax]
  )

  const buttonsView: ReactNode = useMemo(() => {
    const isNotDefaultDate: boolean =
      !!initialDate && !momentCurrentMinMax.current.isSame(initialDate, 'day')
    const cancelButtonTitle: string = t(
      isNotDefaultDate ? 'common.cancel' : 'common.close'
    )
    const showRemoveButton =
      !isNotDefaultDate && canBeRemoved && period.appearance !== 'Addable'
    const confirmButton: ReactNode = isNotDefaultDate && (
      <ConfirmButton
        onClick={() => {
          onExit()
          onConfirm()
        }}
      >
        {t('common.confirm')}
      </ConfirmButton>
    )
    return (
      <ButtonsContainer>
        {showRemoveButton && (
          <RemoveButton appearance={'destructiveBordered'} onClick={onRemove}>
            {t('common.remove')}
          </RemoveButton>
        )}
        <CancelButton appearance={'cancel'} onClick={onCancel}>
          {cancelButtonTitle}
        </CancelButton>
        {confirmButton}
      </ButtonsContainer>
    )
  }, [
    initialDate,
    t,
    momentCurrentMinMax,
    onExit,
    onCancel,
    onRemove,
    period.appearance,
    canBeRemoved,
    onConfirm
  ])

  const onResize = useCallback(() => {
    if (isDesktop && !alignsToTop) {
      forceUpdate()
    }
  }, [forceUpdate, isDesktop, alignsToTop])

  useResizeDetector({
    targetRef: containerRef,
    handleHeight: true,
    onResize
  })

  const alertsView: ReactNode = useMemo(() => {
    if (alerts.length === 0) {
      return null
    }

    return (
      <AlertsContainer>
        {alerts.map((key: string) => (
          <DatePickerAlert
            key={key}
            alertTranslationKey={key}
            metadata={leave.metadata}
          />
        ))}
      </AlertsContainer>
    )
  }, [alerts, leave.metadata])

  const infoMessageView: ReactNode = useMemo(() => {
    const message = period.timelineConfig.datePickerFooterInfoMessage(t)
    return (
      message && (
        <InfoMessageWrapper>
          <InfoMessageView message={message} />
        </InfoMessageWrapper>
      )
    )
  }, [period, t])

  const defaultActiveStartDate = isBlankDate(momentCurrentMinMax.current)
    ? momentCurrentMinMax.max
    : momentCurrentMinMax.current

  const title = useMemo(() => {
    const periodKeyDate = getPeriodKeyDate(period)
    return t('timeline.periodPicker.title.date', {
      periodName: period.timelineConfig.periodName,
      keyDate: periodKeyDate
    })
  }, [t, period])

  return (
    <Container
      ref={containerRef}
      $y={y}
      $alignsToTop={alignsToTop}
      $containerHeight={containerRect.height}
      role={'region'}
      aria-label={t('timeline.accessibility.regionLeaveDurationSettings')}
    >
      <AccessibilitySelfFocusText
        role={'alert'}
        ariaLabel={t('timeline.accessibility.datePickerOpened')}
      />
      <ContentContainer>
        {alertsView}
        <TitleWrapper>{title}</TitleWrapper>
        {period.timelineConfig.datePickerDescription && (
          <Text ref={textRef}>
            {period.timelineConfig.datePickerDescription}
          </Text>
        )}
        {isCalendarMounted ? (
          <div
            role={'region'}
            aria-label={t('timeline.accessibility.regionDatepicker')}
          >
            <Calendar
              defaultActiveStartDate={defaultActiveStartDate}
              momentCurrentMinMax={momentCurrentMinMax}
              onChange={onDateChange}
              style={isDesktop ? Default : DefaultTransparent}
              highlightedDate={initialDate}
              holidays={leaveHolidays}
              onOpened={(date: any) => {
                onCalendarActiveDateChanged(date)
              }}
              onActiveStartDateChange={(action: any) => {
                onCalendarActiveDateChanged(action.activeStartDate)
              }}
            />
          </div>
        ) : (
          <CalendarPlaceholder $height={lastHeight} />
        )}
        {infoMessageView}
        {buttonsView}
      </ContentContainer>
      <ArrowView alignsToTop={alignsToTop} bottomOffset={bottomOffset} />
    </Container>
  )
})

LeaveDateTimelinePicker.displayName = 'LeaveDateTimelinePicker'

export default LeaveDateTimelinePicker
