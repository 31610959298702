import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import { IMomentCurrentMinMax, ITimelinePeriod } from 'src/react-app-env'
import Calendar from 'src/components/Calendar'
import useComponentRect, { IRect } from 'src/components/hooks/useComponentRect'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import { TIMELINE_DETAILS_PUSH_DURATION } from 'src/features/Timeline/components/vertical/animationConstants'
import Default from 'src/components/Calendar/styles/Default'
import DefaultTransparent from 'src/components/Calendar/styles/DefaultTransparent'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/UIKit'
import usePrevious from 'src/components/hooks/usePrevious'
import ArrowView from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/ArrowView'
import AccessibilitySelfFocusText from 'src/components/AccessibilitySelfFocusText'
import ScreenContext from 'src/contexts/ScreenContext'
import {
  mobileButtonContainerPaddingMixin,
  mobileMaxWidthMixin
} from 'src/theme/utils'
import useDate, { IUseDate } from '../hooks/useDate'
import DescriptionText from '../components/DescriptionText'
import * as Methods from './methods'
import InfoMessageView from '../components/InfoMessageView'
import { useResizeDetector } from 'react-resize-detector'
import Title from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Title'

interface IProps {
  className?: string
  period: ITimelinePeriod
  y?: number
  bottomOffset?: number
  alignsToTop?: boolean
  onExit: () => void
}

const Container = styled.div<{
  $y: number
  $alignsToTop: boolean
  $containerHeight: number
}>`
  transition: all ${TIMELINE_DETAILS_PUSH_DURATION}ms;
  display: flex;

  ${props =>
    props.theme.isDesktop
      ? css`
          position: absolute;
          width: 445px;
          filter: drop-shadow(0 0 10px rgb(0 0 0 / 5%));

          ${() => {
            const { $y, $alignsToTop, $containerHeight } = props
            if ($alignsToTop) {
              return css`
                top: ${$y}px;
              `
            } else {
              return css`
                top: ${$y - $containerHeight}px;
                align-items: flex-end;
              `
            }
          }}
        `
      : css`
          width: 100%;
          height: 100%;
          overflow-y: scroll;
          margin: 0 auto;
        `}
  ${mobileMaxWidthMixin};
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.colors.light100};

  ${props =>
    props.theme.isDesktop
      ? css`
          border: 1px solid ${props.theme.colors.dark05};
          border-radius: 8px;
          width: 429px;
          padding: 16px 0;
          overflow: hidden;
        `
      : css`
          margin: 16px 0 0;
          width: 100%;
          height: auto;
        `}
`

const DescriptionTextWrapper = styled(DescriptionText)`
  margin: 0 16px;
  width: calc(100% - 32px);
`

const TitleWrapper = styled(Title)`
  margin: 0 16px 20px;
`

const CalendarPlaceholder = styled.div<{ $height: number }>`
  width: 1px;
  height: ${props => props.$height}px;
`

const ButtonsContainer = styled.div`
  display: flex;
  ${props =>
    props.theme.isDesktop
      ? css`
          width: calc(100% - 32px);
          margin: 16px 0 0;
        `
      : css`
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgb(255 255 255 / 95%);
          justify-content: center;
          align-items: center;
          margin: 16px;
        `}

  ${mobileButtonContainerPaddingMixin};
`

const buttonMixin = css`
  flex: 1;
  ${props =>
    props.theme.isMobile &&
    css`
      font-size: 16px;
      height: 48px;
    `}
`

const CancelButton = styled(Button)`
  ${buttonMixin};
  &:not(:last-child) {
    flex: 1;
    width: 155px;
    margin-right: 16px;
  }
`

const ConfirmButton = styled(Button)`
  ${buttonMixin}
`

const InfoMessageWrapper = styled.div`
  width: calc(100% - 32px);
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 10px 16px;
        `
      : css`
          margin: -52px 10px;
        `}
`

const LeaveKeyDatePicker = React.memo((props: IProps) => {
  const context: ITimelineContext = useContext(TimelineContext)
  const {
    leave,
    leaveStartDate,
    leaveEndDate,
    dueDate,
    updateLeave,
    onNewChanges,
    timelinePeriods,
    leaveHolidays,
    onCalendarActiveDateChanged,
    onCancelChanges
  } = context
  const { period, y, alignsToTop, bottomOffset, onExit } = props
  const { type } = period
  const { t } = useTranslation()
  const { isDesktop, isMobile } = useContext(ScreenContext)
  const [lastHeight, setLastHeight] = useState<number>(420)
  const { leaveKeyDatePickerInfoMessage } = period.timelineConfig

  const start: IMomentCurrentMinMax = period.periodStart.current
    ? period.periodStart
    : { current: period.startDate, min: null, max: null }

  const initialDate: IUseDate = useDate(start)
  const currentDate: IUseDate = useDate(start)
  const [isCalendarMounted, setIsCalendarMounted] = useState(isMobile)
  const [isCanceling, setIsCanceling] = useState(false)
  const prevLeave: ILeave = usePrevious(leave)

  const containerRef: any = useRef(null)
  const containerRect: IRect = useComponentRect(containerRef)
  const textRef: any = useRef(null)
  const textRect: IRect = useComponentRect(textRef)
  const forceUpdate = useForceUpdate()

  useEffect(() => {
    if (isMobile) {
      return
    }

    if (containerRect.height !== 0) {
      setLastHeight(containerRect.height - textRect.height)
    }
    setIsCalendarMounted(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  useEffect(() => {
    if (!isCalendarMounted) {
      setIsCalendarMounted(true)
      requestAnimationFrame(() => {
        forceUpdate()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCalendarMounted])

  useEffect(() => {
    if (isCanceling && prevLeave !== leave) {
      requestAnimationFrame(() => {
        onExit()
      })
    }
  }, [isCanceling, leave, prevLeave, onExit])

  const onDateChange = useCallback(
    (date: any) => {
      Methods.onDateChange({
        date,
        currentDate,
        onNewChanges,
        timelinePeriods,
        period,
        leave,
        startDate: leaveStartDate.current,
        endDate: leaveEndDate.current,
        dueDate: dueDate.current
      })
    },
    [
      leaveStartDate,
      leaveEndDate,
      currentDate,
      onNewChanges,
      timelinePeriods,
      period,
      dueDate,
      leave
    ]
  )

  const isDateChanged: boolean = useMemo(
    () => Methods.isDateChanged({ initialDate, currentDate }),
    [initialDate, currentDate]
  )

  const onConfirm = useCallback(() => {
    Methods.onConfirm({
      onExit,
      updateLeave,
      timelinePeriods,
      period,
      currentDate,
      leave,
      dueDate: dueDate.current,
      startDate: leaveStartDate.current,
      endDate: leaveEndDate.current
    })
  }, [
    currentDate,
    timelinePeriods,
    period,
    updateLeave,
    onExit,
    dueDate,
    leaveStartDate,
    leaveEndDate,
    leave
  ])

  const onCancel = useCallback(() => {
    onExit()
    if (isDateChanged) {
      setIsCanceling(true)
      onCancelChanges()
    }
  }, [isDateChanged, onCancelChanges, onExit])

  const onResize = useCallback(() => {
    if (isDesktop && !alignsToTop) {
      forceUpdate()
    }
  }, [forceUpdate, isDesktop, alignsToTop])

  useResizeDetector({
    targetRef: containerRef,
    handleHeight: true,
    onResize
  })

  const infoMessageView: ReactNode = useMemo(() => {
    const message: string = leaveKeyDatePickerInfoMessage(leave, t)
    return (
      message && (
        <InfoMessageWrapper>
          <InfoMessageView message={message} />
        </InfoMessageWrapper>
      )
    )
  }, [leaveKeyDatePickerInfoMessage, leave, t])

  const buttonsView: ReactNode = useMemo(() => {
    const cancelButtonTitle: string = t(
      isDateChanged ? 'common.cancel' : 'common.close'
    )
    const confirmButton: ReactNode = isDateChanged && (
      <ConfirmButton onClick={onConfirm}>{t('common.confirm')}</ConfirmButton>
    )
    return (
      <ButtonsContainer>
        <CancelButton appearance={'cancel'} onClick={onCancel}>
          {cancelButtonTitle}
        </CancelButton>
        {confirmButton}
      </ButtonsContainer>
    )
  }, [t, isDateChanged, onConfirm, onCancel])

  const title = useMemo(
    () =>
      t('timeline.periodPicker.title.period', {
        periodName: period.timelineConfig.periodName
      }),
    [t, period]
  )

  return (
    <Container
      ref={containerRef}
      $y={y}
      $alignsToTop={alignsToTop}
      $containerHeight={containerRect.height}
      role={'region'}
      aria-label={t('timeline.accessibility.regionLeaveDurationSettings')}
    >
      <AccessibilitySelfFocusText
        role={'alert'}
        ariaLabel={t('timeline.accessibility.datePickerOpened')}
      />
      <ContentContainer>
        <TitleWrapper>{title}</TitleWrapper>
        <DescriptionTextWrapper>
          {period?.timelineConfig?.datePickerDescription}
        </DescriptionTextWrapper>
        {isCalendarMounted ? (
          <div
            role={'region'}
            aria-label={t('timeline.accessibility.regionDatepicker')}
          >
            <Calendar
              momentCurrentMinMax={currentDate}
              onChange={onDateChange}
              style={isDesktop ? Default : DefaultTransparent}
              highlightedDate={initialDate.current}
              holidays={leaveHolidays}
              onOpened={(date: any) => {
                onCalendarActiveDateChanged(date)
              }}
              onActiveStartDateChange={(action: any) => {
                onCalendarActiveDateChanged(action.activeStartDate)
              }}
            />
          </div>
        ) : (
          <CalendarPlaceholder $height={lastHeight} />
        )}
        {infoMessageView}
        {buttonsView}
      </ContentContainer>
      <ArrowView alignsToTop={alignsToTop} bottomOffset={bottomOffset} />
    </Container>
  )
})

LeaveKeyDatePicker.displayName = 'LeaveKeyDatePicker'

export default LeaveKeyDatePicker
