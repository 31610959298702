import Workflow, { Stages } from 'src/utils/workflow'
import { ITimelineContext } from 'src/features/Timeline/Context'
import {
  ICountryCode,
  CreateLeaveStage,
  ITimelinePeriod
} from 'src/react-app-env'

export interface LeaveNoticeItemNextButton {
  textKey: string
  onClick: (p: any) => void
}

export interface LeaveNoticeItem {
  noticeKey: string
  titleKey: string
  bodyTextKey: string
  nextButton?: LeaveNoticeItemNextButton
}

export type LeavePreference = 'starter' | 'reliever' | 'middler'

interface ICustomerTimelineConfig {
  hideBlockIncomeSection(
    leave: ILeave,
    countryCode: string,
    block: IPeriodBlock
  ): boolean
  hasSupplementPay(leave: ILeave): boolean
  getLeaveNoticeItems(
    leave: ILeave,
    countryCode: string,
    timelinePeriods: ITimelinePeriod[]
  ): LeaveNoticeItem[]
  showApprovedDuration(leave: ILeave): boolean
  showNextStepsNotice(leave: ILeave): boolean
  showReviewAlerts: boolean
  showRBTToastMessages: boolean
  showVacationBalanceEdit(leave: ILeave): boolean
  onAfterCloseReviewAlert(timelineContext: ITimelineContext): void
  hasDynamicItemID(countryCode: string): boolean
  showUnplannedBondingTooltipLessThan(unusedBondingDays: number): boolean
  useTpaApproval(leaveType: ILeaveType, countryCode: ICountryCode): boolean
}

interface ICustomerTutorialConfig {
  useOtherTutorialSteps: boolean
  hasVacationStep(leave: ILeave): boolean
}

interface ICustomerPlanChangeConfig {
  showAsteriskNoteView: boolean
  showHint(leave: ILeave): boolean
}

interface ITpaSyncConfig {
  showSyncStatus(leave: ILeave): boolean
  isPeriodNotManagedTpa(leave: ILeave, period: ITimelinePeriod): boolean
  isPeriodNotSynced(leave: ILeave, period: ITimelinePeriod): boolean
  isPeriodNotUpdated(leave: ILeave, period: ITimelinePeriod): boolean
}

interface ITpaSyncSectionConfig {
  showPromptTurnOff(tpaSyncing: boolean): boolean
  showLastUpdate: boolean
}

export interface IProfileSectionConfig {
  hasDynamicPhoneMask(countryCode: ICountryCode): boolean
}

interface ICustomerUserProfileConfig {
  profileSection: IProfileSectionConfig
  tpaSyncSection: ITpaSyncSectionConfig
}

interface ICustomerCreateLeaveWorkflow {
  // mapping ILeaveGroup => Stages
  Family: Stages<CreateLeaveStage>
  Medical: Stages<CreateLeaveStage>
  Military: Stages<CreateLeaveStage>
  Miscarriage: Stages<CreateLeaveStage>
  Parental: Stages<CreateLeaveStage>
  Paternity: Stages<CreateLeaveStage>
  Personal: Stages<CreateLeaveStage>
  Sabbatical: Stages<CreateLeaveStage>
  Sickness: Stages<CreateLeaveStage>
}

export interface ICustomerLeaveCreateConfig {
  hasTpaFlow(workflow: Workflow<CreateLeaveStage>): boolean
  preferences: LeavePreference[]
  showMedicalFiledClaim: boolean
  showMedicalPermanentNote: boolean
  showMilitaryNotSupportedNote: boolean
  showsMoreThanNote(countryCode: ICountryCode): boolean
  showsMultipleBirth: boolean
  showPersonalNotes: boolean
  useFamilyEndDate: boolean
  workflow: ICustomerCreateLeaveWorkflow
}

export interface ICustomerLeaveHolidaysConfig {
  enabled: boolean
}

interface ICustomerLeaveConfig {
  holidays: ICustomerLeaveHolidaysConfig
  create: ICustomerLeaveCreateConfig
  timeline: ICustomerTimelineConfig
  tpaSync: ITpaSyncConfig
  tutorial: ICustomerTutorialConfig
  planChange: ICustomerPlanChangeConfig
}

export interface ICustomerConfig {
  logo: string
  tpa: string
  leave: ICustomerLeaveConfig
  showInfoPage: boolean
  userProfile: ICustomerUserProfileConfig
}

export const defaultCustomerConfig = (): ICustomerConfig => ({
  logo: '',
  tpa: 'TPA',
  leave: {
    holidays: {
      enabled: true
    },
    create: {
      hasTpaFlow: () => false,
      preferences: [],
      showMedicalFiledClaim: false,
      showMedicalPermanentNote: false,
      showMilitaryNotSupportedNote: false,
      showsMoreThanNote: (countryCode: ICountryCode) => countryCode === 'US',
      showsMultipleBirth: false,
      showPersonalNotes: false,
      useFamilyEndDate: true,
      workflow: {
        Family: { family: null },
        Medical: { medical: null },
        Military: { military: null },
        Parental: { parental: null },
        Paternity: { paternity: null },
        Personal: { personal: null },
        Sabbatical: { sabbatical: null },
        Sickness: { sickness: null },
        Miscarriage: {
          miscarriage: ({ selectedLeaveType }) =>
            selectedLeaveType?.type === 'PartnerMiscarriage'
              ? null
              : 'miscarriageLength',

          miscarriageLength: ({ subtype }) =>
            subtype === 'LossBefore24Weeks' ? null : 'miscarriageLeaveStatus'
        }
      }
    }, // create
    timeline: {
      hideBlockIncomeSection: () => false,
      hasSupplementPay: () => false,
      getLeaveNoticeItems: () => [],
      showApprovedDuration: () => false,
      showNextStepsNotice: () => false,
      showReviewAlerts: false,
      showRBTToastMessages: false,
      showVacationBalanceEdit: () => false,
      hasDynamicItemID: () => false,
      onAfterCloseReviewAlert: null,
      showUnplannedBondingTooltipLessThan: () => false,
      useTpaApproval: () => false
    },
    tpaSync: {
      showSyncStatus: () => false,
      isPeriodNotManagedTpa: () => false,
      isPeriodNotSynced: () => false,
      isPeriodNotUpdated: () => false
    },
    tutorial: {
      useOtherTutorialSteps: false,
      hasVacationStep: () => false
    },
    planChange: {
      showAsteriskNoteView: false,
      showHint: () => false
    }
  },
  showInfoPage: false,
  userProfile: {
    profileSection: {
      hasDynamicPhoneMask: () => false
    },
    tpaSyncSection: {
      showPromptTurnOff: () => false,
      showLastUpdate: true
    }
  }
})
