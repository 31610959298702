import React, { useState, useContext, useCallback } from 'react'
import useServiceWorker from 'src/components/hooks/useServiceWorker'
import usePageTitle from 'src/components/hooks/usePageTitle'
import Page from './components/WelcomePage'
import SharedContext from 'src/contexts/SharedContext'
import { singinLocalStorageHook } from 'src/utils/ls'

const WelcomeContainer = React.memo(() => {
  const { customer } = useContext(SharedContext)
  const [signing, setSigning] = useState<boolean>(false)
  const { isUpdateAvailable, updateAssets } = useServiceWorker()

  usePageTitle(null)

  const onSignInHandler = useCallback(() => {
    setSigning(true)
    singinLocalStorageHook()
    if (isUpdateAvailable) {
      updateAssets()
    }
  }, [isUpdateAvailable, updateAssets])

  return (
    <Page
      customer={customer}
      onSignInHandler={onSignInHandler}
      signing={signing}
    />
  )
})

WelcomeContainer.displayName = 'WelcomeContainer'

export default WelcomeContainer
