import moment from 'moment'
import { IIconName } from 'src/react-app-env'
import {
  DatePickerKeyDate,
  IGetPeriodConfigInput,
  ITimelinePeriodConfig
} from '../index'
import LeaveKeyDatePicker from '../../components/vertical/LeaveDurationPickers/LeaveKeyDatePicker'
import LeaveDateSinglePicker from '../../components/vertical/LeaveDurationPickers/LeaveDateSinglePicker'
import { DATE_FORMAT_WITH_DAY } from 'src/utils/dateUtils'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  description: moment(input.period.startDate)
    .utc()
    .format(DATE_FORMAT_WITH_DAY),
  datePickerComponent:
    input.leave.type === 'Adoption' || input.leave.type === 'Partner'
      ? LeaveKeyDatePicker
      : LeaveDateSinglePicker,
  datePickerKeyDate: 'dueDate' as DatePickerKeyDate,
  timeViewProps: {
    ...baseConfig.timeViewProps,
    iconName: 'due_date_baby_head' as IIconName
  }
})
