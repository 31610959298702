import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import {
  IDialogData,
  ITimelinePeriod,
  IWithDialogManager
} from 'src/react-app-env'
import Calendar from 'src/components/Calendar'
import useComponentRect, { IRect } from 'src/components/hooks/useComponentRect'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import { TIMELINE_DETAILS_PUSH_DURATION } from 'src/features/Timeline/components/vertical/animationConstants'
import Default from 'src/components/Calendar/styles/Default'
import DefaultTransparent from 'src/components/Calendar/styles/DefaultTransparent'
import { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/UIKit'
import usePrevious from 'src/components/hooks/usePrevious'
import ArrowView from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/ArrowView'
import DatePickerAlert from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Alert'
import AccessibilitySelfFocusText from 'src/components/AccessibilitySelfFocusText'
import ScreenContext from 'src/contexts/ScreenContext'
import {
  mobileButtonContainerPaddingMixin,
  mobileMaxWidthMixin
} from 'src/theme/utils'
import {
  getPeriodDuration,
  calculateDuration,
  getChangesForPeriod,
  getExtraParameters
} from '../methods'
import { isBlankDate, areSameDates } from 'src/utils/dateUtils'
import { useResizeDetector } from 'react-resize-detector'
import InfoMessageView from '../components/InfoMessageView'
import {
  isCertificationRequired,
  findTimelinePeriod
} from 'src/utils/leaveUtils'
import DescriptionText from '../components/DescriptionText'
import Title from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Title'
import { getPeriodKeyDate } from 'src/utils/periodUtils'
import SelectedMessage from '../components/SelectedMessage'
import ApprovedByTpaView from '../components/ApprovedByTpaView'
import { withDialogManager } from 'src/components/DialogManager'
import RemoveDialogContent from '../components/RemoveDialogContent'

interface IProps extends IWithDialogManager {
  className?: string
  period: ITimelinePeriod
  y?: number
  alignsToTop?: boolean
  bottomOffset?: number
  onExit: () => void
}

const Container = styled.div<{
  $y: number
  $alignsToTop: boolean
  $containerHeight: number
}>`
  transition: all ${TIMELINE_DETAILS_PUSH_DURATION}ms;
  display: flex;

  ${props =>
    props.theme.isDesktop
      ? css`
          position: absolute;
          width: 445px;
          filter: drop-shadow(0 0 10px rgb(0 0 0 / 5%));

          ${() => {
            const { $y, $alignsToTop, $containerHeight } = props
            if ($alignsToTop) {
              return css`
                top: ${$y}px;
              `
            } else {
              return css`
                top: ${Math.max(0, $y - $containerHeight)}px;
                align-items: flex-end;
              `
            }
          }}
        `
      : css`
          width: 100%;
          height: 100%;
          overflow-y: scroll;
          margin: 0 auto;
        `}
  ${mobileMaxWidthMixin};
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.colors.light100};

  ${props =>
    props.theme.isDesktop
      ? css`
          border: 1px solid ${props.theme.colors.dark05};
          border-radius: 8px;
          width: 429px;
          padding-bottom: 16px;
          overflow: hidden;
        `
      : css`
          width: 100%;
          height: auto;
        `}
`

const TitleWrapper = styled(Title)`
  margin: 16px 16px 20px;
`

const DescriptionTextWrapper = styled(DescriptionText)`
  width: calc(100% - 32px);
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 16px;
        `
      : css`
          margin: 8px 24px 0;
        `}
`

const SelectedNoticeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: self-start;
  margin: 0 16px;
`

const CalendarPlaceholder = styled.div<{ $height: number }>`
  width: 1px;
  height: ${props => props.$height}px;
`

const ButtonsContainer = styled.div`
  display: flex;
  ${props =>
    props.theme.isDesktop
      ? css`
          width: calc(100% - 32px);
          margin: 16px 0 0;
        `
      : css`
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgb(255 255 255 / 95%);
          justify-content: center;
          align-items: center;
          margin: 16px;
        `}

  ${mobileButtonContainerPaddingMixin};
`

const buttonMixin = css`
  flex: 1;
  ${props =>
    props.theme.isMobile &&
    css`
      font-size: 16px;
      height: 48px;
    `}
`

const CancelButton = styled(Button)`
  ${buttonMixin};
  &:not(:last-child) {
    flex: 1;
    width: 155px;
    margin-right: 16px;
  }
`

const RemoveButton = CancelButton

const ConfirmButton = styled(Button)`
  ${buttonMixin}
`

const AlertsContainer = styled.div`
  width: 100%;
  width: calc(100% - 32px);
  margin: 16px 0 8px;
`

const InfoMessageWrapper = styled.div`
  width: calc(100% - 32px);
  margin: 10px 16px;
`

const ApprovedByTpaWrapper = styled.div`
  width: calc(100% - 32px);
  margin: 0 16px;
`

/**
 * Sends period changes with ID field.
 */
const LeaveDatePeriodPicker = React.memo((props: IProps) => {
  const context: ITimelineContext = useContext(TimelineContext)
  const {
    leave,
    onNewChanges,
    timelinePeriods,
    hasTimelineChanges,
    leaveHolidays,
    onCalendarActiveDateChanged,
    onCancelChanges
  } = context
  const { y, alignsToTop, bottomOffset, onExit, period, dialogManager } = props
  const { type, appearance } = period
  const { t } = useTranslation()
  const { isDesktop, isMobile } = useContext(ScreenContext)

  const [lastHeight, setLastHeight] = useState<number>(420)
  const [initialDate, setInitialDate] = useState<Moment>(null)
  const [initialPeriod] = useState(period)
  // There is an issue https://github.com/wojtekmaj/react-calendar/issues/342
  // So, every time 'type' prop changes, we re-mount the calendar on desktop
  const [isCalendarMounted, setIsCalendarMounted] = useState(isMobile)
  const [isDurationApproved, setIsDurationApproved] = useState<boolean>(false)
  const hadTimelineChanges: boolean = usePrevious(hasTimelineChanges)
  const [wasAddable] = useState(appearance === 'Addable')

  const containerRef: any = useRef(null)
  const containerRect: IRect = useComponentRect(containerRef)
  const forceUpdate = useForceUpdate()

  if (initialPeriod.refID) {
    period.refID = initialPeriod.refID
  }

  useEffect(() => {
    setInitialDate(null)
  }, [type])

  useEffect(() => {
    if (!hasTimelineChanges && hadTimelineChanges) {
      setInitialDate(null)
    }
  }, [hasTimelineChanges, hadTimelineChanges])

  useEffect(() => {
    if (isMobile) {
      return
    }

    if (containerRect.height !== 0) {
      setLastHeight(containerRect.height)
    }
    setIsCalendarMounted(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  useEffect(() => {
    if (!isCalendarMounted) {
      setIsCalendarMounted(true)
      requestAnimationFrame(() => {
        forceUpdate()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCalendarMounted])

  const removeDialogView: IDialogData = useMemo(
    () => ({
      title: t('timeline.removeDialog.title'),
      buttons: [
        {
          title: t('common.cancel'),
          appearance: 'cancel',
          onClick: (): void => undefined,
          order: 1
        },
        {
          title: t('timeline.removeDialog.yes'),
          onClick: () => {
            onExit()
            const extra = getExtraParameters({
              period,
              isDurationApproved
            })
            const changes: any = getChangesForPeriod({
              period,
              intermittentBlockChanges: { isNew: wasAddable },
              duration: 0,
              extra
            })
            onNewChanges(changes, true)
          },
          order: 2
        }
      ],
      children: (
        <RemoveDialogContent
          periodType={period.type}
          isDurationApproved={isDurationApproved}
          setIsDurationApproved={setIsDurationApproved}
        />
      )
    }),
    [isDurationApproved, onExit, onNewChanges, period, t, wasAddable]
  )

  // When parameters that have context dependencies are used in a dependency, there is a constant change in those parameters.
  // For now it is decided to track the change of state in dependency.
  useEffect(() => {
    if (dialogManager?.opened) {
      dialogManager.update(removeDialogView)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDurationApproved])

  const alerts: string[] = Array.from(period.timelineConfig.alerts)

  if (
    period.type === 'Recovery' &&
    isCertificationRequired(leave, period.type)
  ) {
    alerts.push(t('postDeliveryDisabilityCertificateRequired'))
  }

  if (
    (period.type === 'PreDelivery' || period.type === 'Sickness') &&
    isCertificationRequired(leave, period.type) &&
    !alerts.includes('preDeliverySickTimeCertificateRequired')
  ) {
    alerts.push(t('preDeliverySickTimeCertificateRequired'))
  }

  const canBeRemoved = useMemo(() => period.isRemovable, [period])
  const onRemove = useCallback(() => {
    onExit()
    const changes: any = getChangesForPeriod({
      period,
      intermittentBlockChanges: { isNew: wasAddable },
      duration: 0
    })
    onNewChanges(changes, true)
  }, [onNewChanges, onExit, period, wasAddable])

  const onRemoveWithDialog = useCallback(() => {
    dialogManager.add(removeDialogView)
  }, [dialogManager, removeDialogView])

  const resetChanges = useCallback(() => {
    const changes: any = getChangesForPeriod({
      period: initialPeriod,
      intermittentBlockChanges: { isNew: wasAddable },
      duration: getPeriodDuration(initialPeriod)
    })
    onNewChanges(changes)
  }, [initialPeriod, onNewChanges, wasAddable])

  const updatedPeriod = findTimelinePeriod(timelinePeriods, props.period)
  const momentCurrentMinMax = useMemo(
    () =>
      updatedPeriod
        ? updatedPeriod[updatedPeriod.timelineConfig.periodKeyDate]
        : { current: null, min: null, max: null },
    [updatedPeriod]
  )

  const onCancel = useCallback(() => {
    onExit()
    onCancelChanges()
  }, [onExit, onCancelChanges])

  const onChange = useCallback(
    (date: Moment) => {
      if (initialDate && areSameDates(date, initialDate)) {
        return resetChanges()
      }
      const { periodKeyDate } = updatedPeriod.timelineConfig
      const changes: any = getChangesForPeriod({
        period,
        intermittentBlockChanges: { isNew: wasAddable },
        duration: calculateDuration(updatedPeriod, periodKeyDate, date)
      })
      onNewChanges(changes)
    },
    [onNewChanges, resetChanges, initialDate, updatedPeriod, period, wasAddable]
  )

  useEffect(() => {
    if (
      period.appearance === 'Addable' &&
      period.timelineConfig.periodPickerCreateAddable
    ) {
      const { periodKeyDate } = period.timelineConfig
      const date =
        periodKeyDate === 'periodEnd'
          ? period.periodEnd.max
          : period.periodStart.min
      onDateChange(date)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onConfirm = useCallback(() => {
    const extra = getExtraParameters({
      period,
      isDurationApproved
    })
    const changes: any = getChangesForPeriod({
      period,
      intermittentBlockChanges: { isNew: wasAddable },
      duration: getPeriodDuration(updatedPeriod),
      extra
    })
    onNewChanges(changes, true)
  }, [onNewChanges, period, updatedPeriod, wasAddable, isDurationApproved])

  const onDateChange = useCallback(
    (d: any) => {
      if (!initialDate) {
        setInitialDate(momentCurrentMinMax.current)
      }

      onChange(d)
    },
    [initialDate, onChange, momentCurrentMinMax]
  )

  const isNotDefaultDate: boolean =
    !!initialDate && !momentCurrentMinMax.current?.isSame(initialDate, 'day')

  const buttonsView: ReactNode = useMemo(() => {
    const cancelButtonTitle: string = t(
      isNotDefaultDate ? 'common.cancel' : 'common.close'
    )
    const showRemoveButton =
      !isNotDefaultDate && canBeRemoved && period.appearance !== 'Addable'
    const confirmButton: ReactNode = isNotDefaultDate && (
      <ConfirmButton
        onClick={() => {
          onExit()
          onConfirm()
        }}
      >
        {t('common.confirm')}
      </ConfirmButton>
    )
    return (
      <ButtonsContainer>
        {showRemoveButton && (
          <RemoveButton
            appearance={'destructiveBordered'}
            onClick={
              period.timelineConfig.useApprovedByTpa
                ? onRemoveWithDialog
                : onRemove
            }
          >
            {t('common.remove')}
          </RemoveButton>
        )}
        <CancelButton appearance={'cancel'} onClick={onCancel}>
          {cancelButtonTitle}
        </CancelButton>
        {confirmButton}
      </ButtonsContainer>
    )
  }, [
    isNotDefaultDate,
    t,
    onExit,
    onCancel,
    onRemove,
    period,
    canBeRemoved,
    onConfirm,
    onRemoveWithDialog
  ])

  const onResize = useCallback(() => {
    if (isDesktop && !alignsToTop) {
      forceUpdate()
    }
  }, [forceUpdate, isDesktop, alignsToTop])

  useResizeDetector({
    targetRef: containerRef,
    handleHeight: true,
    onResize
  })

  const getBottomOffset = useCallback(
    () =>
      !alignsToTop
        ? Math.max(bottomOffset, containerRect.height - y)
        : bottomOffset,
    [alignsToTop, bottomOffset, containerRect.height, y]
  )

  const alertsView: ReactNode = useMemo(() => {
    if (alerts.length === 0) {
      return null
    }

    return (
      <AlertsContainer>
        {alerts.map((key: string) => (
          <DatePickerAlert
            key={key}
            alertTranslationKey={key}
            metadata={leave.metadata}
          />
        ))}
      </AlertsContainer>
    )
  }, [alerts, leave.metadata])

  const infoMessageView: ReactNode = useMemo(() => {
    const message = period.timelineConfig.datePickerFooterInfoMessage(t, leave)
    return (
      message && (
        <InfoMessageWrapper>
          <InfoMessageView message={message} />
        </InfoMessageWrapper>
      )
    )
  }, [leave, period, t])

  const selectedPeriodView: ReactNode = useMemo(
    () =>
      updatedPeriod?.timelineConfig?.periodPickerSelectedNotice.show &&
      updatedPeriod?.schedule && (
        <SelectedMessage
          used={updatedPeriod.schedule.usedInPeriod}
          unused={updatedPeriod.schedule.totalUnused}
          usedCalendarDays={updatedPeriod.schedule?.usedInPeriodCalendarDays}
          unusedCalendarDays={updatedPeriod.schedule?.totalUnusedCalendarDays}
          externalDurationType={updatedPeriod.schedule.durationType}
          showDoubleText={
            updatedPeriod.timelineConfig?.periodPickerSelectedNotice
              .showDoubleText
          }
          notShowUnused={
            updatedPeriod.timelineConfig?.periodPickerSelectedNotice
              .notShowUnused
          }
        />
      ),
    [updatedPeriod]
  )

  let defaultActiveStartDate = momentCurrentMinMax.current
  if (isBlankDate(defaultActiveStartDate)) {
    const keyDate =
      period.timelineConfig.defaultActiveKeyDate ||
      period.timelineConfig.periodKeyDate
    defaultActiveStartDate =
      keyDate === 'periodStart'
        ? momentCurrentMinMax.min
        : momentCurrentMinMax.max
  }

  const title = useMemo(
    () =>
      t('timeline.periodPicker.title.date', {
        periodName: period.timelineConfig.periodName,
        keyDate: getPeriodKeyDate(period)
      }),
    [t, period]
  )

  const approvedByTpaView: ReactNode = useMemo(
    () =>
      period.timelineConfig.useApprovedByTpa && (
        <ApprovedByTpaWrapper>
          <ApprovedByTpaView
            approved={isDurationApproved}
            hasChanges={isNotDefaultDate}
            onClick={() => {
              setIsDurationApproved(!isDurationApproved)
            }}
          />
        </ApprovedByTpaWrapper>
      ),
    [isDurationApproved, setIsDurationApproved, isNotDefaultDate, period]
  )

  return (
    <Container
      ref={containerRef}
      $y={y}
      $alignsToTop={alignsToTop}
      $containerHeight={containerRect.height}
      role={'region'}
      aria-label={t('timeline.accessibility.regionLeaveDurationSettings')}
    >
      <AccessibilitySelfFocusText
        role={'alert'}
        ariaLabel={t('timeline.accessibility.datePickerOpened')}
      />
      <ContentContainer>
        {alertsView}
        <TitleWrapper>{title}</TitleWrapper>
        {period.timelineConfig?.datePickerDescription && (
          <DescriptionTextWrapper>
            {period.timelineConfig.datePickerDescription}
          </DescriptionTextWrapper>
        )}
        {isCalendarMounted ? (
          <div
            role={'region'}
            aria-label={t('timeline.accessibility.regionDatepicker')}
          >
            <Calendar
              holidays={leaveHolidays}
              defaultActiveStartDate={defaultActiveStartDate}
              momentCurrentMinMax={momentCurrentMinMax}
              onChange={onDateChange}
              style={isDesktop ? Default : DefaultTransparent}
              highlightedDate={initialDate}
              onOpened={(date: any) => {
                onCalendarActiveDateChanged(date)
              }}
              onActiveStartDateChange={(action: any) => {
                onCalendarActiveDateChanged(action.activeStartDate)
              }}
            />
          </div>
        ) : (
          <CalendarPlaceholder $height={lastHeight} />
        )}
        <SelectedNoticeWrapper>{selectedPeriodView}</SelectedNoticeWrapper>
        {infoMessageView}
        {approvedByTpaView}
        {buttonsView}
      </ContentContainer>
      <ArrowView alignsToTop={alignsToTop} bottomOffset={getBottomOffset()} />
    </Container>
  )
})

LeaveDatePeriodPicker.displayName = 'LeaveDatePeriodPicker'

export default withDialogManager(LeaveDatePeriodPicker)
