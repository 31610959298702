import React from 'react'
import styled, { css } from 'styled-components'
import { Icon } from 'src/UIKit'
import createTheme from 'src/theme'
import {
  mobileMaxWidthMixin,
  timelineNoticeCloseButtonMixin,
  timelineNoticeContainerMixin,
  timelineNoticeTextMixin
} from 'src/theme/utils'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from 'react-i18next'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'

interface IProps {
  className?: string
  noticeAttr: IAlert
  onCloseNotice: (id: string) => void
}

const { colors } = createTheme()

const Container = styled.div<{ $top?: number }>`
  ${timelineNoticeContainerMixin};
  ${mobileMaxWidthMixin};
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const Title = styled.p`
  font-size: 14px;
  color: ${props => props.theme.colors.dark60};
  line-height: 130%;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-weight: 500;
        `
      : css`
          font-weight: bold;
        `}
`

const CloseButton = styled.button`
  ${timelineNoticeCloseButtonMixin};
`

const IconWrapper = styled(Icon)`
  padding-top: 2px;
`

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const Text = styled(ReactMarkdown)`
  ${timelineNoticeTextMixin};
  white-space: pre-wrap;

  a {
    color: ${props => props.theme.colors.main100};
    font-weight: normal;
    line-height: 150%;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    ${props =>
      props.theme.isMobile &&
      css`
        font-size: 12px;
      `}
  }
`

export const dataAttrs = {
  closeBtn: () => 'external-notice-close-btn'
}

const ExternalNotice = React.memo((props: IProps) => {
  const {
    className,
    noticeAttr: { content, id, title },
    onCloseNotice
  } = props
  const { t } = useTranslation()

  return (
    <Container
      className={className}
      role={'region'}
      aria-label={t('timeline.accessibility.regionExternalNotice')}
      $top={0}
    >
      <TitleContainer>
        <Title>{title}</Title>
        <CloseButton
          data-testid={dataAttrs.closeBtn()}
          onClick={() => {
            onCloseNotice(id)
          }}
          aria-label={t('common.close')}
        >
          <IconWrapper
            name={'close_crossing'}
            settings={{
              fill: colors.main100
            }}
          />
        </CloseButton>
      </TitleContainer>
      <TextContainer>
        <Text
          urlTransform={TelUrlTransformer}
          components={{
            a: LinkRenderer
          }}
        >
          {content}
        </Text>
      </TextContainer>
    </Container>
  )
})

ExternalNotice.displayName = 'ExternalNotice'

export default ExternalNotice
