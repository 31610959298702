import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { Icon } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import ScreenContext from 'src/contexts/ScreenContext'
import SharedContext from 'src/contexts/SharedContext'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const DisclaimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: center;

  ${props =>
    props.theme.isMobile &&
    css`
      background: rgb(255 255 255 / 30%);
      padding: 16px 8px 0;
    `}
`

const DisclaimerIconWrapper = styled(Icon)`
  display: flex;
  align-items: center;
  margin-right: 11px;
  margin-left: 4px;
`

const DisclaimerText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: ${props => (props.theme.isDesktop ? '11px' : '10px')};
  line-height: 130%;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop &&
    css`
      width: 700px;
      height: 26px;
    `}
`

export const Copyright = styled.span`
  font-weight: normal;
  font-size: 10px;
  line-height: 130%;
  color: ${props => props.theme.colors.dark60};
  margin: 8px 0 4px 8px;

  ${props =>
    props.theme.isDesktop &&
    css`
      display: none;
    `}
`

const Disclaimer = React.memo(() => {
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)
  const year: number = new Date().getFullYear()
  const { customer } = useContext(SharedContext)

  return (
    <Container>
      <DisclaimerContainer>
        {isDesktop && (
          <DisclaimerIconWrapper
            name={'shield'}
            ariaLabel={t('common.accessibilityText.shield')}
          />
        )}
        <DisclaimerText>
          {t('footer.disclaimer', { company: customer || 'company' })}
        </DisclaimerText>
      </DisclaimerContainer>
      <Copyright>{`© ${year} ${t('footer.copyright')}`}</Copyright>
    </Container>
  )
})

Disclaimer.displayName = 'Disclaimer'

export default Disclaimer
