import styled, { css } from 'styled-components'
import DatePicker from 'src/components/DatePicker'

const datePickerMixin = css`
  width: 100%;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 20px 0 0;
        `
      : css`
          margin: 16px 0 0;
        `}
`

const StyledDatePicker = styled(DatePicker)<{ $margin?: number }>`
  ${datePickerMixin}

  ${props =>
    props.$margin &&
    css`
      margin: ${props.$margin};
    `}
`

export { datePickerMixin, StyledDatePicker }
