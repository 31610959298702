import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import cache from './i18n-cache-busting.json'
import { ICustomerConfig } from './config/customers/config'

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    load: 'currentOnly',
    partialBundledLanguages: true,
    lng: 'en-US',
    fallbackLng: 'en-US',
    ns: ['general'],
    fallbackNS: ['general'],
    debug: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    backend: {
      loadPath: (lngs, namespaces) => {
        const [lng] = lngs
        const [ns] = namespaces

        if (ns === 'general' && lng !== 'en-US') {
          return null // skip loading non US general namespace
        }

        return `/i18n/${ns}/${lng}-${cache[ns][lng]}.json`
      }
    }
  })

const setupDefaultVariables = (props: any) => {
  const interpolator = i18n.services.interpolator as any
  interpolator.options.interpolation.defaultVariables = {
    ...interpolator.options.interpolation.defaultVariables,
    ...props
  }
}

export const setupTranslation = async (user: IUser) => {
  const lng = `en-${user.countryCode}`

  if (i18n.language !== lng) {
    await i18n.changeLanguage(lng)
  }

  setupDefaultVariables({
    countryCode: user.countryCode,
    countryState: user.countryState,
    userFirstName: user.firstName,
    userLastName: user.lastName
  })
}

export const setupNamespaces = (customer: string, config: ICustomerConfig) => {
  if (customer) {
    i18n.setDefaultNamespace(customer)
    setupDefaultVariables({ customer, tpa: config.tpa })
  }
}

export default i18n
